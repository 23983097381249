import image1 from "../assets/mh2.jpg";
import image2 from "../assets/lucas3.jpg";
import image3 from "../assets/lucas2.jpg";

export const aboutData = [
  {
    image: image1,
    review:
      "Lorem Ipsum é simplesmente uma simulação de texto da indústria tipográfica e de impressos, ",
    name: "DAVI FREITAS",
    status: "Um pouco mais sobre mim...",
  },
  {
    image: image2,
    review:
      "Lorem Ipsum é simplesmente uma simulação de texto da indústria tipográfica e de impressos, e ",
    name: "BRUNO CALDEIRA",
    status: "Um pouco mais sobre mim...",
  },
  {
    image: image3,
    review:
      "Lorem Ipsum é simplesmente uma simulação de texto da indústria tipográfica e de impressos, e ",
    name: "Eduardo Cavalvante",
    status: "Um pouco mais sobre mim...",
  },
];
